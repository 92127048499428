<template>
  <main class="container mx-auto flex flex-col items-center mt-6 px-4">
    <div class="mb-8">
      <router-link
        to="/submission"
        class="px-8 py-2 flex items-center rounded-md font-medium text-sm text-white bg-secondary-500 transition-all duration-300"
        >提出 BOX へ</router-link
      >
    </div>
    <m-a-graph-panel :student="student" />
    <m-learning-summary-section
      v-if="student"
      :has-badges="hasBadges"
      :student="student"
      class="mb-8"
    />
    <div class="flex justify-between mb-4">
      <router-link
        to="/analyze/future"
        class="py-2 px-8 mr-4 rounded-full font-bold cursor-pointer"
        :class="futureButtonCss"
      >
        予定
      </router-link>
      <router-link
        to="/learning"
        class="py-2 px-8 ml-4 rounded-full font-bold cursor-pointer"
        :class="historyButtonCss"
      >
        履歴
      </router-link>
    </div>
    <MAFuturePanel
      v-if="learningPageType == 'future'"
      :reservations="reservations"
      :regularly-reservations="regularlyReservations"
      @delete="deleteReservation"
      @deleteRegular="deleteRegularlyReservation"
    />
    <MAHistoryPanel
      v-else-if="learningPageType == 'history'"
      :learnings="filteredLearnings"
      :loadable="!learningFinished && learnings.length >= learningLimit"
      :loading="learningLoading"
      @load="getMoreLearning"
    />
  </main>
</template>

<script lang="ts">
import { fetchHasBadges } from "@/api/badge";
import { saveErrorLog } from "@/api/error";
import {
  deleteRegularlyReservation,
  deleteReservation,
  getRegularlyReservationsOf,
  getReservationsOf
} from "@/api/reservation";
import MAFuturePanel from "@/components/analyze/MAFuturePanel.vue";
import MAHistoryPanel from "@/components/analyze/MAHistoryPanel.vue";
import MAGraphPanel from "@/components/analyze/MAGraphPanel.vue";
import MLearningSummarySection from "@/components/learning/MLearningSummarySection.vue";
import { HasBadge } from "@/entities/badge";
import { Learning } from "@/entities/learning";
import { RegularlyReservation } from "@/entities/regularly_reservation";
import { Reservation } from "@/entities/reservation";
import { Student } from "@/entities/student";
import store, { LearningPageType, awaitStudentLoaded } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MAFuturePanel,
    MAGraphPanel,
    MAHistoryPanel,
    MLearningSummarySection
  }
})
export default class LearningView extends Vue {
  reservations: Reservation[] = [];
  regularlyReservations: RegularlyReservation[] = [];
  hasBadges: HasBadge[] = [];

  get student(): Student | null {
    return store.state.student;
  }

  get filteredLearnings(): Learning[] {
    return this.learnings.filter(learning => learning.data.totalTime > 0);
  }

  get learnings(): Learning[] {
    return store.state.learnings;
  }

  get learningLoading(): boolean {
    return store.state.learningLoading;
  }

  get learningFinished(): boolean {
    return store.state.learningFinished;
  }

  get learningLimit(): number {
    return store.state.learningLimit;
  }

  get learningPageType(): LearningPageType {
    return store.state.learningPageType;
  }

  get futureButtonCss() {
    if (this.learningPageType === "future") {
      return {
        "bg-secondary-500": true,
        "text-white": true
      };
    } else {
      return {
        "text-secondary-500": true
      };
    }
  }

  get historyButtonCss() {
    if (this.learningPageType === "history") {
      return {
        "bg-secondary-500": true,
        "text-white": true
      };
    } else {
      return {
        "text-secondary-500": true
      };
    }
  }

  async deleteReservation(id: string) {
    const matchReservations = this.reservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.reservations = this.reservations.filter(item => item.ref.id !== id);
    try {
      await deleteReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`学習予約の削除に失敗しました\n\n${e}`);
      this.reservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete reservation"
      );
    }
  }

  async deleteRegularlyReservation(id: string) {
    const matchReservations = this.regularlyReservations.filter(
      item => item.ref.id === id
    );
    if (matchReservations.length === 0) {
      return;
    }
    this.regularlyReservations = this.regularlyReservations.filter(
      item => item.ref.id !== id
    );
    try {
      await deleteRegularlyReservation(matchReservations[0].ref);
    } catch (e) {
      alert(`学習予約の削除に失敗しました\n\n${e}`);
      this.regularlyReservations.push(matchReservations[0]);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete regularlyReservation"
      );
    }
  }

  async getMoreLearning() {
    store.dispatch("getMoreLearning");
  }

  async created() {
    const student = await awaitStudentLoaded(store);
    this.getReservations(student);
    const hasBadges = await fetchHasBadges(student.ref);
    this.hasBadges = hasBadges;
  }

  async getReservations(student: Student) {
    try {
      const [reservations, regularlyReservations] = await Promise.all([
        getReservationsOf(student.ref, Math.floor(Date.now() / 1000)),
        getRegularlyReservationsOf(student.ref)
      ]);
      this.reservations = reservations;
      this.regularlyReservations = regularlyReservations;
    } catch (e) {
      alert(`学習予約の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to fetch reservation datas"
      );
    }
  }
}
</script>
